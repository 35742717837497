@font-face {
  font-family: 'Harmonia Sans';
  src: local('Harmonia Sans Regular'), url(./fonts/HarmoniaSansRegular.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Harmonia Sans';
  src: local('Harmonia Sans Semi Bold'), url(./fonts/HarmoniaSansSemiBold.ttf) format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Harmonia Sans';
  src: local('Harmonia Sans Bold'), url(./fonts/HarmoniaSansBold.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Harmonia Sans';
  src: local('Harmonia Sans Black'), url(./fonts/HarmoniaSansBlack.ttf) format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Harmonia Sans';
  src: local('Harmonia Sans Light'), url(./fonts/HarmoniaSansLight.ttf) format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Harmonia Sans';
  src: local('Harmonia Sans Italic'), url(./fonts/HarmoniaSansItalic.ttf) format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Harmonia Sans';
  src: local('Harmonia Sans Semi Bold Italic'), url(./fonts/HarmoniaSansSemiBoldItalic.ttf) format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Harmonia Sans';
  src: local('Harmonia Sans Bold Italic'), url(./fonts/HarmoniaSansBoldItalic.ttf) format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Harmonia Sans';
  src: local('Harmonia Sans Light Italic'), url(./fonts/HarmoniaSansLightItalic.ttf) format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Harmonia Sans';
  src: local('Harmonia Sans Black Italic'), url(./fonts/HarmoniaSansBlackItalic.ttf) format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: local('SourceSansPro-Regular'), url(./fonts/SourceSansPro-Regular.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: local('SourceSansPro-SemiBold'), url(./fonts/SourceSansPro-SemiBold.ttf) format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: local('SourceSansPro-Bold'), url(./fonts/SourceSansPro-Bold.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: local('SourceSansPro-Black'), url(./fonts/SourceSansPro-Black.ttf) format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: local('SourceSansPro-Light'), url(./fonts/SourceSansPro-Light.ttf) format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: local('SourceSansPro-Italic'), url(./fonts/SourceSansPro-Italic.ttf) format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: local('SourceSansPro-SemiBoldItalic'), url(./fonts/SourceSansPro-SemiBoldItalic.ttf) format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: local('SourceSansPro-BoldItalic'), url(./fonts/SourceSansPro-BoldItalic.ttf) format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: local('SourceSansPro-LightItalic'), url(./fonts/SourceSansPro-LightItalic.ttf) format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: local('SourceSansPro-BlackItalic'), url(./fonts/SourceSansPro-BlackItalic.ttf) format('truetype');
  font-weight: 900;
  font-style: italic;
}

body {
  margin: 0;
  background-color: #FFFFFF;
  font-family: -apple-system, 'Source Sans Pro', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  font-family: -apple-system, 'Source Sans Pro', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

@media (min-width: 960px) {
  html {
    overflow-y: scroll;
    scrollbar-width: thin;
  }

  body {
    background-color: rgb(248, 249, 251);
  }
}

@media (max-height: 180px) {
  html,
  body {
    overflow: hidden !important;
    pointer-events: none;
  }
  header {
    display: none !important;

    & + div {
      padding: 0 !important;
    }
  }
}

a {
  text-decoration: none;
  color: unset;
}

//remove arrows from number inputs
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

::-webkit-scrollbar {
  background-color: transparent;
  height: 5px;
  width: 5px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #dfe2e9;
  border-radius: 6px;
  cursor: pointer;
}

::-webkit-scrollbar-thumb:horizontal:hover,
::-webkit-scrollbar-thumb:vertical:hover {
  background-color: rgba(105, 123, 155, 0.4);
}

::-webkit-scrollbar-thumb:horizontal:active,
::-webkit-scrollbar-thumb:vertical:active {
  background-color: #697b9b;
}

::-moz-placeholder,
::-webkit-input-placeholder {
  color: #a9b6cb;
}

@keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0);
  }
  10% {
    transform: scale(1.1, 0.9) translateY(0);
  }
  30% {
    transform: scale(0.9, 1.1) translateY(-10px);
  }
  50% {
    transform: scale(1.05, 0.95) translateY(0);
  }
  57% {
    transform: scale(1, 1) translateY(-7px);
  }
  64% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
}

.checkmark {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #4bb71b;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px #4bb71b;
  animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
  position: relative;
  top: 5px;
  right: 5px;
  margin: 0 auto;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #4bb71b;
  fill: #fff;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.bounceAnimation {
  animation: bounce 1.5s infinite;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%,
  100% {
    transform: none;
  }

  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #4bb71b;
  }
}
